@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Montserrat", sans-serif;
}
.header h1{
  margin: 0 !important;
  padding: 0 !important;
}


::-webkit-scrollbar {
  width: 6px;
}


::-webkit-scrollbar-track {
  background-color: #0a192f;
}


::-webkit-scrollbar-thumb {
  background: #013f3c7f;
  border-radius: 10px;
}


i{
  cursor: pointer;
}
i:hover{
  color: #f5f5f5;
}



.k{
  animation: k 1s infinite;
}

@keyframes k{
  0%{
      opacity: 1;
  }
  50%{
      opacity: 0.5;
  }
  100%{
      opacity: 0.5;
  }
}


.s{
  animation: s 1s infinite;
}

@keyframes s{
  0%{
      opacity: 0.5;
  }
  50%{
      opacity: 1;
  }
  100%{
      opacity: 0.5;
  }
}


.r{
  animation: r 1s infinite;
}

@keyframes r{
  0%{
      opacity: 0.5;
  }
  50%{
      opacity: 0.5;
  }
  100%{
      opacity: 1;
  }
}



input , textarea{
  height: 40px;
  border: 1px solid gray;
  width: 100%;
  padding-left: 20px;
}

input:focus{
  outline: none;
  border: 2px solid rgb(56, 56, 56);
}

textarea{
  height: 100px;
  padding-top: 10px;
}



.scroll-container::-webkit-scrollbar {
  display: none;
}


.scroll-container {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}